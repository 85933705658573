<template>
  <section
    id="tracking-list"
    class="d-flex justify-center relative">
    <div class="tracking-list-content-box">
      <v-row class="header-toolbar">
        <v-col
          cols="12"
          md="2">
          <date-with-menu
            v-model="filter.startDate"
            :default-date="null"
            label="From"
            dense />
        </v-col>
        <v-col
          cols="12"
          md="2">
          <date-with-menu
            v-model="filter.endDate"
            :default-date="null"
            label="To"
            dense />
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="2">
          <v-select
            v-model="filter.factory"
            :items="selectOptions.factories"
            label="Factory"
            outlined
            dense
            hide-details />
        </v-col>
        <v-col
          cols="12"
          md="2">
          <v-select
            v-model="filter.state"
            :items="selectOptions.states"
            label="Status"
            outlined
            dense
            hide-details />
        </v-col>
        <v-col
          cols="12"
          md="2">
          <v-combobox
            v-model="filter.collection"
            :items="selectOptions.collections"
            item-text="text"
            item-value="value"
            label="Collection"
            outlined
            hide-details
            dense />
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="d-flex justify-end align-center">
          <span class="font-weight-bold">
            ทั้งหมด {{ paginate.totalItems | showNumberFormat() }} แบบ
          </span>
        </v-col>
        <v-col
          cols="12"
          md="4">
          <search-box
            v-model="filter.search"
            @on-search="getLists()" />
        </v-col>
      </v-row>
      <v-row class="content-row">
        <v-col cols="12">
          <div class="table-wrap">
            <v-data-table
              :headers="headers"
              :items="lists"
              :loading="tableLoading"
              :items-per-page="15"
              item-key="id"
              class="tracking-table"
              hide-default-footer
              dense>
              <template #[`item.image`]="{ item }">
                <img
                  :src="getFittingImage(item) || getSketchImage(item)"
                  class="detail-box-image" />
              </template>
              <template #[`item.order.factory.name`]="{ value }">
                <span>
                  {{ value || '-' }}
                </span>
              </template>
              <template #[`item.sketch.sketchId`]="{ item }">
                <a
                  class="font-weight-bold text-decoration-underline"
                  :href="getPrototypeUrl(item)"
                  target="_blank">
                  {{ item.sketch.sketchId }}
                </a>
              </template>
              <template #[`item.order.orderDate`]="{ item }">
                <span v-if="item.order">
                  {{ item.order.orderDate | dateUserFormat() }}
                </span>
                <span v-else>
                  -
                </span>
              </template>
              <template #[`item.order.targetDate`]="{ item }">
                <span v-if="item.order">
                  {{ item.order.targetDate | dateUserFormat() }}
                </span>
                <span v-else>
                  -
                </span>
              </template>
              <template #[`item.total`]="{ item }">
                <span v-if="item.order">
                  {{ totalSkuAmountCompute(item.order.productSkus) }}
                </span>
                <span v-else>
                  -
                </span>
              </template>
              <template #[`item.days`]="{ item }">
                <span v-if="item.order">
                  {{ diffDays($dayjs(), item.order.orderDate) }}
                </span>
                <span v-else>
                  -
                </span>
              </template>
              <template #[`item.manufacStatus`]="{ item }">
                <v-chip
                  v-if="!item.order || !item.manufac"
                  class="manufac-status-btn text-capitalize"
                  :ripple="false"
                  color="#e0e0e0"
                  elevation="0"
                  x-small>
                  On {{ item.state }}
                </v-chip>
                <v-chip
                  v-else-if="isOverdue(item.order.targetDate, item.manufac.manufacStatus)"
                  class="manufac-status-btn"
                  :ripple="false"
                  color="#ff121e"
                  elevation="0"
                  x-small>
                  <span style="color: white;">
                    Overdue
                  </span>
                </v-chip>
                <v-chip
                  v-else-if="item.manufac.manufacStatus === 'on_progress'"
                  class="manufac-status-btn"
                  :ripple="false"
                  color="#f7f063"
                  elevation="0"
                  x-small>
                  On Progress
                </v-chip>
                <v-chip
                  v-else-if="item.manufac.manufacStatus === 'delivered_ch'"
                  class="manufac-status-btn"
                  :ripple="false"
                  color="#4cc76f"
                  elevation="0"
                  x-small>
                  Delivered CH
                </v-chip>
                <v-chip
                  v-else-if="item.manufac.manufacStatus === 'delivered_th'"
                  class="manufac-status-btn"
                  :ripple="false"
                  color="#4cc76f"
                  elevation="0"
                  x-small>
                  Delivered TH
                </v-chip>
                <v-chip
                  v-else-if="item.manufac.manufacStatus === 'stopped'"
                  class="manufac-status-btn"
                  :ripple="false"
                  color="#b5b3b3"
                  elevation="0"
                  x-small>
                  STOPPED
                </v-chip>
              </template>
            </v-data-table>
          </div>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-space-between">
          <!-- <v-btn
            outlined
            class="mx-2 "
            @click="exportCsv()">
            Export CSV
          </v-btn> -->
          <v-pagination
            v-if="!tableLoading"
            v-model="filter.page"
            :total-visible="5"
            :length="paginate.totalPages" />
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import FactoryProvider from '@/resources/FactoryProvider'
import ManufacProvider from '@/resources/ManufacProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import SearchBox from '@/components/SearchBox.vue'
import DateWithMenu from '../components/DateWithMenu.vue'

const FactoryService = new FactoryProvider()
const ManufacService = new ManufacProvider()
const ProductAttributeService = new ProductAttributeProvider()

export default {
  components: {
    DateWithMenu,
    SearchBox
  },
  data () {
    return {
      tableLoading: false,
      lists: [],
      selectOptions: {
        factories: [],
        collections: [],
        states: [
          { text: 'All', value: '' },
          { text: 'On Progress', value: 'on_progress' },
          { text: 'Delivered CH', value: 'delivered_ch' },
          { text: 'Delivered TH', value: 'delivered_th' },
          { text: 'Stopped', value: 'stopped' },
          { text: 'Overdue', value: 'overdue' }
        ]
      },
      filter: {
        startDate: '',
        endDate: '',
        search: '',
        designer: '',
        merchandiser: '',
        state: '',
        factory: '',
        collection: '',
        page: 1
      },
      paginate: {
        sortBy: ['manufac.createdAt'],
        sortDesc: ['true'],
        page: 1,
        itemsPerPage: 15,
        totalPages: 10,
        totalItems: 0
      },
      headers: [
        {
          text: 'Image',
          sortable: false,
          align: 'center',
          value: 'image'
        },
        {
          text: 'Factory Name',
          sortable: false,
          align: 'center',
          value: 'order.factory.name'
        },
        {
          text: 'Sketch ID',
          sortable: false,
          align: 'center',
          value: 'sketch.sketchId'
        },
        {
          text: 'Order Date',
          sortable: false,
          align: 'center',
          value: 'order.orderDate'
        },
        {
          text: 'Target Date',
          sortable: false,
          align: 'center',
          value: 'order.targetDate'
        },
        {
          text: 'Collection',
          sortable: false,
          align: 'center',
          value: 'sketch.collectionName'
        },
        {
          text: 'Designer',
          sortable: false,
          align: 'center',
          value: 'sketch.designer.name'
        },
        {
          text: 'Merchandiser',
          sortable: false,
          align: 'center',
          value: 'sketch.merchandiser.name'
        },
        {
          text: 'State',
          sortable: false,
          align: 'center',
          value: 'state',
          cellClass: 'text-capitalize'
        },
        {
          text: 'Total',
          sortable: false,
          align: 'center',
          value: 'total'
        },
        {
          text: 'Days',
          sortable: false,
          align: 'center',
          value: 'days'
        },
        {
          text: 'Status',
          sortable: false,
          align: 'center',
          value: 'manufacStatus'
        }
      ]
    }
  },
  watch: {
    'filter.page': {
      handler () {
        this.getLists()
      },
      deep: true
    }
  },
  mounted () {
    this.getLists()
    this.getOptions()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading',
      setPrototypes: 'Prototypes/setPrototypes'
    }),
    async getOptions () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING OPTIONS...'
        })
        const { data: factories } = await FactoryService.getAll()
        const { data: attributes } = await ProductAttributeService.getAllProductAttribute()

        this.selectOptions.factories = [
          { value: '', text: 'All' },
          { divider: true },
          ...factories.results.map((item) => ({ value: item.name, text: item.name }))
        ]

        this.selectOptions.collections = attributes.collections.map((item) => ({ value: item.name, text: item.name }))
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async getLists () {
      try {
        this.tableLoading = true
        const startDate = this.filter.startDate ? this.$dayjs(this.filter.startDate).utc().format('YYYY-MM-DD') : ''
        const endDate = this.filter.endDate ? this.$dayjs(this.filter.endDate).utc().format('YYYY-MM-DD') : ''
        const filter = {
          ...this.filter,
          collection: this.filter?.collection?.trim(),
          startDate,
          endDate
        }
        const { data } = await ManufacService.getTracking({ ...this.paginate, ...filter })
        this.lists = data.results
        this.paginate.totalPages = data.pages
        this.paginate.totalItems = data.total
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.tableLoading = false
      }
    },
    getFittingImage (item) {
      if (item?.order?.fitting?.length) {
        const fitting = (item.order?.fitting && Array.isArray(item.order.fitting))
          ? [...item.order?.fitting]
          : [item.order?.fitting]

        let lastFitting = fitting[fitting.length - 1]

        for (let i = (fitting.length - 1); i >= 0; i--) {
          const { images } = fitting[i]

          if (images.length) {
            lastFitting = fitting[i]
            break
          }
        }

        return lastFitting?.images[0] || null
      }

      return null
    },
    getSketchImage (item) {
      if (item?.sketch?.images?.length) {
        return item.sketch?.images[0] || null
      }

      return null
    },
    getPrototypeUrl (item) {
      if (item.version === 'v1') {
        switch (item.state) {
          case 'manufacturing': return `/prototype/order/edit-old/${item.id}`
          case 'order': return `/prototype/order/edit-old/${item.id}`
          case 'sketch': return `/prototype/sketch/edit-old/${item.id}`
          default: return `/prototype/sketch/edit-old/${item.id}`
        }
      }

      switch (item.state) {
        case 'manufacturing': return `/prototype/order/edit/${item.id}`
        case 'order': return `/prototype/order/edit/${item.id}`
        case 'sketch': return `/prototype/sketch/edit/${item.id}`
        default: return `/prototype/sketch/edit/${item.id}`
      }
    },
    diffDays (date1, date2) {
      return this.$dayjs(date1).diff(this.$dayjs(date2), 'day') || '-'
    },
    totalSkuAmountCompute (sku) {
      return sku.reduce((total, acc) => total + acc.quantity, 0)
    },
    isOverdue (date, status) {
      return this.$dayjs().isAfter(this.$dayjs(date)) && status === 'on_progress'
    },
    exportCsv () {
        const preExport = this.lists.map((order) => ({
          'Sketch ID': order.sketch?.sketchId || '-',
          'Order ID': order.order?.orderId || '-',
          'Product ID': order.order?.productId || '-',
          'Product Factory ID': order.order?.productFactoryId || '-',
          'Category': order.sketch?.category?.name || '-',
          'Collection': order.sketch?.collectionName || '-',
          'Designer': order.sketch?.designer?.name || '-',
          'Merchandiser': order.sketch?.merchandiser?.name || '-',
          'Factory': order.order?.factory?.name || '-',
          'Date': this.$dayjs(order?.createdAt).format('YYYY-MM-DD') || '-',
          'OrderDate': this.$dayjs(order?.order?.orderDate).format('YYYY-MM-DD') || '-',
          'TargetDate': this.$dayjs(order?.order?.targetDate).format('YYYY-MM-DD') || '-',
          'สถานะ': order?.order?.orderStatus || '-',
          'โน๊ต': order.sketch?.note || '-',
          'Sizing Detail': this.preExportSizingDetail(order.order.sizeDetail),
          'Product SKUS': this.preExportProductSkus(order.order.productSkus),
          'ราคาขาย': order.order?.sellPrice || '-',
          'ราคาผ้า': order.order?.clothesCostPerMetr || '-',
          'ราคาผลิต': (order.order?.manufacCountry === 'thailand' ? order.order?.manufacCost : order.order?.manufacCost * 5) || '-',
          'โรงงาน': order.order?.manufacCountry || '-'
        }))

        const options = {
          filename: `MANUFACTURES(${this.$dayjs().format('YYYY-MM-DDTHH-mm')})`,
          showLabels: true,
          useKeysAsHeaders: true
        }
        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(preExport)
    },
    preExportSizingDetail (sizeDetail) {
      const result = sizeDetail.map((detail) => (
        detail.options.map((option) => `${detail.size}(${option.key})=${option.value}`)
      ))

      return result.toString()
    },
    preExportProductSkus (productSkus) {
      const result = productSkus.map((detail) => (
        `[size=${detail.size}, color=${detail.color}, quantity=${detail.quantity}, ratio=${detail.ratio}]`
      ))
      return result.toString()
    }
  }
}
</script>

<style>
.tracking-list-content-box {
  position: relative;
  padding: 15px 15px;
  /* background-color: #fff; */
  width: 100%;
  /* max-width: 1375px; */
}

.tracking-list-content-box .content-row {
  height: initial !important;
}

.tracking-table .v-data-table__wrapper {
  height: 100%
}

.tracking-list-content-box .header-toolbar {
  position: sticky;
  top: 50px;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 1px 2px 5px 0px #c2c2c2;
  min-height: 66px;
}

.field-label {
  font-weight: bold;
}

.tracking-list-content-box .content-row {
  position: relative;
  height: 85vh;
  padding: 10px 0;
  background-color: #fff;
}

.manufac-status-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

img.detail-box-image {
  /* cursor: pointer; */
  margin: 0 auto;
  max-height: 80px;
  transition: transform 0.1s;
}

img.detail-box-image:active {
  transform: scale(0.95);
}
</style>
